import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const markInterestShiftRequestSchema = z.object({
  shift: z.string(),
  docVer: z.string().optional(),
});

const markInterestShiftResponseSchema = z.object({});

export type MarkInterestShiftRequest = z.infer<typeof markInterestShiftRequestSchema>;
export type MarkInterestShiftResponse = z.infer<typeof markInterestShiftResponseSchema>;

export const MARK_INTEREST_SHIFT_PATH = "/shift/interested";
export const MARK_INTEREST_SHIFT_URL = `${environmentConfig.REACT_APP_BASE_API_URL}${MARK_INTEREST_SHIFT_PATH}`;

export function useMarkInterestShift(
  options: UseMutationOptions<MarkInterestShiftResponse, AxiosError, MarkInterestShiftRequest> = {}
): UseMutationResult<MarkInterestShiftResponse, AxiosError, MarkInterestShiftRequest> {
  return useMutation({
    mutationFn: async (variables: MarkInterestShiftRequest) => {
      const response = await post({
        url: MARK_INTEREST_SHIFT_URL,
        data: variables,
        requestSchema: markInterestShiftRequestSchema,
        responseSchema: markInterestShiftResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_MARK_INTEREST_SHIFT_FAILURE,
    },
    ...options,
  });
}
