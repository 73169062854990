import { AgentStage } from "@src/appV2/Agents/types";
import { Banner } from "@src/appV2/lib/Alert";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { WorkerAvailabilityBanners } from "@src/appV2/WorkerAvailability/components/WorkerAvailabilityBanner/WorkerAvailabilityBanners";

import { AccountRestrictionBanner } from "./AccountRestrictionBanner";

export function WorkerBanners() {
  const worker = useDefinedWorker();

  return (
    <>
      {worker.stage === AgentStage.RESTRICTED && <AccountRestrictionBanner />}
      {worker.stage === AgentStage.DEACTIVATED && (
        <Banner severity="error">Your account has been deactivated.</Banner>
      )}
      <WorkerAvailabilityBanners worker={worker} />
    </>
  );
}
