import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { type ClaimShiftResponse, claimShiftResponseSchema } from "../types";

export enum ShiftSearchMode {
  CALENDAR = "CALENDAR",
  DATE = "DATE",
  TIME_SLOT = "TIME_SLOT",
  MAP = "MAP",
  HCF_PROFILE = "HCF_PROFILE",
  URGENT_SHIFTS = "URGENT_SHIFTS",
  NEGOTIATING_SHIFTS = "NEGOTIATING_SHIFTS",
  NON_QFA_RANGE_SHIFTS = "NON_QFA_RANGE_SHIFTS",
  WORKER_AVAILABILITY = "WORKER_AVAILABILITY",
}

const claimShiftRequestSchema = z.object({
  shiftId: z.string(),
  offerId: z.string(),
  searchMode: z.nativeEnum(ShiftSearchMode).optional(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),
});

export type ClaimShiftRequest = z.infer<typeof claimShiftRequestSchema>;

export const CLAIM_SHIFT_PATH = "/instantBookShifts/claim";
export const CLAIM_SHIFT_URL = `${environmentConfig.REACT_APP_BASE_API_URL}${CLAIM_SHIFT_PATH}`;

export function useClaimShift(
  options: UseMutationOptions<ClaimShiftResponse, AxiosError, ClaimShiftRequest> = {}
): UseMutationResult<ClaimShiftResponse, AxiosError, ClaimShiftRequest> {
  return useMutation({
    mutationFn: async (variables: ClaimShiftRequest) => {
      const response = await post({
        url: CLAIM_SHIFT_URL,
        data: variables,
        requestSchema: claimShiftRequestSchema,
        responseSchema: claimShiftResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_CLAIM_SHIFT_FAILURE,
    },
    ...options,
  });
}
