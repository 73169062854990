import { isDefined } from "@clipboard-health/util-ts";
import { Box, Skeleton, Stack } from "@mui/material";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";
import { useGetAgentFriends } from "@src/appV2/Agents/api/useGetAgentFriends";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { type Shift, ShiftPeriod, ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { format, isAfter, parseISO } from "date-fns";
import { groupBy } from "lodash";
import { z } from "zod";

import { useAgentShifts } from "../api/useAgentShifts";
import { type ShiftSlotsRequest, useGetShiftSlots } from "../api/useGetShiftSlots";
import { useOpenShifts } from "../api/useOpenShifts";
import { OpenShiftFilters } from "../components/OpenShiftCalendar/OpenShiftFilters";
import { shiftPeriodIcons, shiftPeriodLabels } from "../constants";
import { groupShiftsByDateAndPeriod } from "../utils";
import { generateShiftSlots } from "../utils/generateShiftSlots";
import { groupFriendsByShiftPeriod } from "../utils/groupFriendsByShiftPeriod";
import { ShiftPeriodCard } from "./ShiftPeriodCard";
import { type OnShiftPeriodClickProps } from "./types";

interface DayViewProps {
  date: string;
  onShiftPeriodClick: (props: OnShiftPeriodClickProps) => void;
}

export function OpenShiftDayView(props: DayViewProps) {
  const { date: urlDate, onShiftPeriodClick } = props;
  const parsedDate = z.date().parse(parseISO(urlDate));

  const worker = useDefinedWorker();

  const { filters, setFilters } = useAgentPreferences();

  const {
    data: openShiftsData,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
  } = useOpenShifts(
    {
      dateFilter: {
        start: format(parsedDate, "yyyy-MM-dd"),
        end: format(parsedDate, "yyyy-MM-dd"),
      },
      coordinates: worker.geoLocation?.coordinates ?? [0, 0],
      distance: worker.preference.distance ?? 150,
      qualification: worker.preference.qualification ?? "",
      specialities: {
        hasSedationExperience: worker.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: worker.specialities?.hasTrayAssemblyExperience ?? false,
      },
    },
    {
      enabled:
        isDefined(filters?.distance) &&
        isDefined(filters?.license) &&
        worker.geoLocation?.coordinates.length === 2,
    }
  );

  const {
    data: agentShiftsData,
    isLoading: agentShiftsIsLoading,
    isSuccess: agentShiftsIsSuccess,
  } = useAgentShifts({
    startDate: parsedDate,
    endDate: parsedDate,
    tmz: worker.tmz,
    groupByDate: false,
  });

  const shiftSlots: ShiftSlotsRequest["workplaceSlots"] = generateShiftSlots(
    agentShiftsData?.agentShifts ?? [],
    openShiftsData?.openAgentShifts ?? []
  );

  const { data: shiftSlotsData } = useGetShiftSlots(
    {
      workplaceSlots: shiftSlots,
    },
    {
      enabled: openShiftsIsSuccess && agentShiftsIsSuccess && shiftSlots.length > 0,
    }
  );

  const { data: workerFriendsData } = useGetAgentFriends();

  const friendsByShiftPeriod = groupFriendsByShiftPeriod({
    openShifts: openShiftsData?.openAgentShifts ?? [],
    shiftSlots: shiftSlotsData ?? [],
    workerFriends: workerFriendsData ?? [],
  });

  const openShiftsGroupedByShiftPeriod = openShiftsIsSuccess
    ? groupBy(
        openShiftsData?.openAgentShifts.filter((shift) => !shift.filtered),
        (shift: Shift) => {
          return shift.name;
        }
      )
    : { [ShiftPeriod.AM]: [], [ShiftPeriod.PM]: [], [ShiftPeriod.NOC]: [] };

  const agentShiftsGroupedByDateAndShiftPeriod = agentShiftsIsSuccess
    ? groupShiftsByDateAndPeriod(agentShiftsData.agentShifts ?? [], worker.tmz)
    : {};

  const agentShiftsCountGroupedByShiftPeriod = agentShiftsGroupedByDateAndShiftPeriod[urlDate];

  useLogEffect(APP_V2_USER_EVENTS.OPEN_SHIFTS_DAY_VIEW_VIEWED);

  return (
    <>
      <Box sx={{ paddingBottom: 2 }} aria-label="Open shift day view filters">
        <OpenShiftFilters
          agent={worker}
          disabled={openShiftsIsLoading ?? agentShiftsIsLoading}
          filters={filters ?? { distance: 150, license: "" }}
          setFilters={setFilters}
        />
      </Box>

      {openShiftsIsLoading ?? agentShiftsIsLoading ? (
        <Stack spacing={2}>
          {shiftPeriodLabels.map((shiftPeriodLabel) => {
            return (
              <Skeleton key={shiftPeriodLabel} variant="rectangular" width="100%" height={105} />
            );
          })}
        </Stack>
      ) : (
        <Stack spacing={3}>
          {shiftPeriodLabels.map((shiftPeriodLabel) => {
            const openShiftsForPeriod = openShiftsGroupedByShiftPeriod[shiftPeriodLabel] ?? [];
            const agentShiftsForPeriod =
              agentShiftsCountGroupedByShiftPeriod?.[shiftPeriodLabel] ?? 0;
            const friendsForShiftPeriod = friendsByShiftPeriod.get(shiftPeriodLabel) ?? [];

            const Icon = shiftPeriodIcons[shiftPeriodLabel];
            const isActive = openShiftsForPeriod.length > 0 || agentShiftsForPeriod > 0;

            const priorityShiftCount = openShiftsForPeriod.filter(
              (shift) =>
                isDefined(shift?.priorityTill) && isAfter(parseISO(shift.priorityTill), new Date())
            ).length;

            const aTeamShiftCount = openShiftsForPeriod.filter(
              (shift) => shift.window === ShiftWindow.A_TEAM
            ).length;

            return (
              <ShiftPeriodCard
                key={shiftPeriodLabel}
                label={shiftPeriodLabel}
                isActive={isActive}
                Icon={Icon}
                openShifts={openShiftsForPeriod}
                hasBookedShift={agentShiftsForPeriod > 0}
                priorityShiftCount={priorityShiftCount}
                aTeamShiftCount={aTeamShiftCount}
                shiftFriends={friendsForShiftPeriod}
                onClick={onShiftPeriodClick}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
}
