import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { BannerLink } from "@src/appV2/lib/Alert";
import { ATeamHcpStatus } from "@src/appV2/Rankings/types";
import { useGetATeamWorkplaces } from "@src/appV2/Rankings/useGetATeamWorkplaces";

const DEFAULT_MESSAGE =
  "Your account is restricted. You may only book and work shifts at workplaces where you are a favorite worker.";

function getRestrictionMessage(
  enablePAExclusionExperiment: boolean,
  isAteamEnabled: boolean,
  aTeamWorkplacesCount: number,
  aTeamStatus: ATeamHcpStatus
): string {
  const messageConditions = [
    {
      condition: enablePAExclusionExperiment && isAteamEnabled,
      message:
        "Your account is Restricted. You may only book and work shifts at workplaces where you have Priority Plus status, though you may not receive Priority Access notifications for some facilities.",
    },
    {
      condition:
        enablePAExclusionExperiment &&
        aTeamWorkplacesCount > 0 &&
        aTeamStatus !== ATeamHcpStatus.OFF,
      message:
        "Your account is Restricted. You may only book and work shifts at workplaces where you have Priority Plus status or are a Favorite professional, though you may not receive Priority Access notifications for some facilities.",
    },
    {
      condition: isAteamEnabled,
      message:
        "Your account is restricted. You may only book and work shifts at workplaces where you have Priority Plus status.",
    },
    {
      condition: aTeamWorkplacesCount > 0 && aTeamStatus !== ATeamHcpStatus.OFF,
      message:
        "Your account is restricted. You may only book and work shifts at workplaces where you have Priority Plus status or are a Favorite professional.",
    },
    {
      condition: enablePAExclusionExperiment,
      message:
        "Your account is Restricted. You may only book and work shifts at workplaces where you are a Favorite, though you may not receive Priority Access notifications for some facilities.",
    },
  ];

  return messageConditions.find(({ condition }) => condition)?.message ?? DEFAULT_MESSAGE;
}

export function AccountRestrictionBanner() {
  const enablePAExclusionExperiment = useCbhFlag(CbhFeatureFlag.PA_EXCLUSION_EXPERIMENT, {
    defaultValue: false,
  });

  const { aTeamStatus, aTeamWorkplacesCount } = useGetATeamWorkplaces();
  const isAteamEnabled = aTeamStatus === ATeamHcpStatus.A_TEAM;
  const message = getRestrictionMessage(
    enablePAExclusionExperiment,
    isAteamEnabled,
    aTeamWorkplacesCount,
    aTeamStatus
  );

  return (
    <BannerLink to={DeprecatedGlobalAppV1Paths.RATINGS_ATTENDANCE_SCORE} severity="warning">
      {message}
    </BannerLink>
  );
}
