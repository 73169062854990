import { isDefined } from "@clipboard-health/util-ts";
import { type UseGetQueryOptions } from "@src/appV2/api";
import { useMemo } from "react";

import { OPEN_SHIFT_LIST_PAGE_SIZE } from "../../constants";
import { type BookabilityStatusItem, type BookabilityStatusResponse } from "../types";
import { useBookabilityStatus, usePaginatedBookabilityStatus } from "./useBookabilityStatus";

const EMPTY_SHIFT_BOOKABILITY_BY_ID = {};

interface BookabilityStatusProps {
  shiftIds: string[];
  agentId: string;
}
export function useGetBookabilityStatusForShifts(
  props: BookabilityStatusProps,
  options: Pick<UseGetQueryOptions<BookabilityStatusResponse>, "enabled"> = {}
) {
  const { shiftIds, agentId } = props;
  const { enabled = true } = options;

  const bookabilityStatusQueryResult = useBookabilityStatus(
    {
      workerId: agentId,
      shiftIds,
      returnFirstUnmetCriteria: true,
    },
    {
      enabled: isDefined(agentId) && shiftIds.length > 0 && enabled,
    }
  );

  const { data: shiftBookabilityData, isSuccess: shiftBookabilitySuccess } =
    bookabilityStatusQueryResult;

  const shiftBookability = shiftBookabilitySuccess ? shiftBookabilityData.data : [];

  return {
    shiftBookabilityById: shiftBookability.reduce<Record<string, BookabilityStatusItem>>(
      (shiftBookabilityAccumulator, item) => {
        const shiftId = item.id;
        return { ...shiftBookabilityAccumulator, [shiftId]: item };
      },
      {}
    ),
    queryResult: bookabilityStatusQueryResult,
  };
}

export function useGetPaginatedBookabilityStatusForShifts(props: BookabilityStatusProps) {
  const { shiftIds, agentId } = props;

  const paginatedBookabilityStatusQueryResult = usePaginatedBookabilityStatus(
    {
      workerId: agentId,
      shiftIds,
      returnFirstUnmetCriteria: true,
    },
    {
      enabled: isDefined(agentId) && shiftIds.length > 0,
    }
  );

  const { data: paginatedShiftBookability } = paginatedBookabilityStatusQueryResult;

  const shiftBookabilityById: Record<string, BookabilityStatusItem> = useMemo(() => {
    if (!paginatedShiftBookability?.pages) {
      return {};
    }

    const result: Record<string, BookabilityStatusItem> = {};
    for (const shiftBookabilityPage of paginatedShiftBookability.pages) {
      shiftBookabilityPage.data.forEach((item) => {
        result[item.id] = item;
      });
    }

    return result;
  }, [paginatedShiftBookability?.pages]);

  if (!isDefined(paginatedShiftBookability)) {
    return {
      shiftBookabilityById: EMPTY_SHIFT_BOOKABILITY_BY_ID,
      queryResult: paginatedBookabilityStatusQueryResult,
    };
  }

  return {
    shiftBookabilityById,
    bookabilityLoadedShiftsCount:
      paginatedShiftBookability.pages.length * OPEN_SHIFT_LIST_PAGE_SIZE,
    queryResult: paginatedBookabilityStatusQueryResult,
  };
}
