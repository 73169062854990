import { InternalLink } from "@clipboard-health/ui-react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Badge, IconButton } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useLocation } from "react-router-dom";

import { APP_V2_USER_EVENTS, logEvent } from "../lib/analytics";
import { useUnreadNotificationsCount } from "./api/useUnreadNotificationsCount";

export function NotificationCenterButton() {
  const { pathname } = useLocation();
  const unreadNotificationsCount = useUnreadNotificationsCount();

  return (
    <Badge
      overlap="circular"
      badgeContent={unreadNotificationsCount > 0 ? unreadNotificationsCount : undefined}
      color="error"
      sx={{
        "& .MuiBadge-badge": {
          top: `8px`,
        },
      }}
    >
      <IconButton
        aria-label="Open notification center"
        color="inherit"
        component={InternalLink}
        to={DeprecatedGlobalAppV1Paths.NOTIFICATION_CENTER}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.NOTIFICATION_CENTER_VIEWED, {
            context: pathname.includes("openShifts") ? "openShifts" : "myShifts",
            n_unread_messages: unreadNotificationsCount,
            has_unread_badge: unreadNotificationsCount > 0,
          });
        }}
      >
        <NotificationsOutlinedIcon sx={{ color: "white" }} />
      </IconButton>
    </Badge>
  );
}
