import * as braze from "@braze/web-sdk";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { APP_V2_USER_EVENTS, logEvent } from "../../lib/analytics";

function getUnreadNotificationsCount(): number {
  return braze
    .getCachedContentCards()
    .cards.filter((card) => card?.extras?.type === "NOTIFICATION_CENTER" && !card.viewed).length;
}

export function useUnreadNotificationsCount(): number {
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const { pathname } = useLocation();
  const isLoggedNotificationsIconViewedEventRef = useRef(false);

  useEffect(() => {
    const pollBraze = () => {
      braze.requestContentCardsRefresh(() => {
        const unreadCount = getUnreadNotificationsCount();
        setUnreadNotificationsCount(unreadCount);

        /**
         * We only want to log this event once per session
         */
        if (!isLoggedNotificationsIconViewedEventRef.current) {
          logEvent(APP_V2_USER_EVENTS.NOTIFICATION_CENTER_ICON_VIEWED, {
            context: pathname.includes("openShifts") ? "openShifts" : "myShifts",
            n_unread_messages: unreadCount,
            has_unread_badge: unreadCount > 0,
          });

          isLoggedNotificationsIconViewedEventRef.current = true;
        }
      });
    };

    const timer = setInterval(pollBraze, 60 * 1000); // 1 minute

    return () => {
      clearInterval(timer);
    };
  }, [pathname]);

  return unreadNotificationsCount;
}
